<div class="bc-container">
    <div class="bc-row bc-justify-content-center" style="text-align: justify;" *ngIf="stepPresent == 1">
        <div class="bc-col-md-10">
            <h6 class="title">
                A través de esta opción, puedes consultar y pagar tu crédito. Solo necesitas ingresar algunos datos y nosotros te guiamos en el resto del proceso:
            </h6>
            <br><br>
        </div>
    </div>


    <div #targetScroll>
    <div class="bc-row bc-justify-content-center">
        <div class="bc-col-md-8">
            <bc-stepper [steps]="stepsArray" #stepperPagos aria-labelledby="title">
            </bc-stepper>
        </div>
    </div>

    <div *ngIf="!activateMobile">
        <br><br><br><br>
    </div>

    <app-loading-screen [hidden]="requesting == false"></app-loading-screen>

    <div [hidden]="requesting == true">
        <div #step1Content *ngIf="stepPresent == 1">
            <app-step-one></app-step-one>
        </div>

        <div #step2Content *ngIf="stepPresent == 2 || stepPresent == 3">
            <app-step-two   [customer]="responseCustomer" (cancel)="cancelar()"  (goPayment)="goPayment($event)" ></app-step-two>
        </div>

        <div #step3Content *ngIf="stepPresent == 3"  >
            <app-step-three [customer]="responseCustomer"  [form]="formularioTwo" [step]="stepPresent" ></app-step-three>
        </div>

        <div #step4Content *ngIf="stepPresent == 4">
            <app-step-four></app-step-four>
        </div>


        <div class="bc-row bc-justify-content-center">
            <div class="bc-col-sm-auto bc-col-xs-5" [ngClass]="{ 'bc-mt-3': activateMobile }">
                <button bc-button class="btn-def" id="buttonCancel" typeButton="secondary" (click)="cancelPayment()" sizeButton="small">{{ buttonCancel }}</button>
            </div>
            <div class="bc-col-sm-auto bc-col-xs-5" [ngClass]="{ 'bc-order-first': activateMobile }">
                <button bc-button id="buttonNextStep" (click)="nextStep()" sizeButton="small">{{ botonContinue }}</button>
            </div>
        </div>
    </div>

    <br><br>
</div>
