import { RequestCustomer } from './../../../domain/models/request-customer';
import { CustomerUseCase } from './../../../domain/usecases/customer-usecase';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BcDialogRef, BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { constants } from 'src/app/domain/models/constants';
import { LoadingServiceService } from 'src/app/infraestructure/services/loading-service/loading-service.service';
import { DataFormPayment } from 'src/app/domain/models/dataFormPayment';


@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss'],
  providers: [DatePipe]
})
export class StepOneComponent implements OnInit {
  // Error de consulta
  errorConsulta: boolean = false;
  // Formulario
  formStepOne: UntypedFormGroup;

  tokenCaptcha: string;
  keyCaptcha: string;
  errorMessage: string;
  tokenSubscription: Subscription;
  customerSubscription: Subscription;
  @ViewChild('invisibleCaptcha') captcha: RecaptchaComponent;

  // Variables para asignar estato de error si los inputs estan vacios y quiere pasar a paso 2
  stateDocType: string = "";
  stateDocNum: string = "";
  statePayRef: string = "";

  createForm() {
    return new UntypedFormGroup({
      documentType: new UntypedFormControl('', Validators.required),
      documentNumber: new UntypedFormControl('', [Validators.minLength(6), Validators.required, Validators.maxLength(11)]),
      paymentReference: new UntypedFormControl('', [Validators.minLength(11), Validators.maxLength(11), Validators.required])
    });
  }

  constructor(
    private datePipe: DatePipe,
    private dialogService: BcDialogService,
    private customerUseCase: CustomerUseCase,
    public serviceLoading: LoadingServiceService
    )
    {
      this.keyCaptcha = environment.siteKeyCaptcha;
      this.errorMessage = "-";
    }

  ngOnInit(): void {
    this.formStepOne = this.createForm();
  }

  removeAllTagNgVersion(): void{
    const bodyElements = Array.from(document.body.getElementsByTagName('*'));
    for(const element of bodyElements){
        element.removeAttribute("ng-version");
    }
  }


  // Input select
  optionsSelect = [
    {
      label: 'Cédula de ciudadanía',
      value: 'CC'
    },
    {
      label: 'Cédula de extranjería',
      value: 'CE'
    },
    {
      label: 'NIT',
      value: 'NIT'
    }
  ];

  // Alert
  dialogReferenciaPago: BcDialogRef;
  public alertReferenciaPagoCreado = false;
  dialogError: BcDialogRef;
  public alertErrorCreado: boolean = false;


  openAlertReferenciaPago(): void {
    if (!this.alertReferenciaPagoCreado) {
      this.dialogReferenciaPago = this.dialogService.open(BcAlertComponent, {
        type: 'info',
        title: 'Referencia de pago',
        text: 'Encuentra el número de referencia de pago de tu crédito en la parte superior de tu extracto o en el mensaje de texto que te enviamos mensualmente.',
        elementRef: 'paymentReferenceField',
      });

      this.alertReferenciaPagoCreado = true;

      this.dialogReferenciaPago.onResult().subscribe(
        (closed) => this.closedReferenciaPago(),
        (dismissed) => this.dismissed(),
        () => this.competed()
      );
    }
    this.removeAllTagNgVersion();
  }

  closedReferenciaPago() {
    this.alertReferenciaPagoCreado = false;
  }

  dismissed() {}

  competed() {}

  openAlertError(): void {
    if (!this.alertErrorCreado) {
      this.dialogError = this.dialogService.open(BcAlertComponent, {
        type: 'error',
        title: this.errorMessage,
        inline: true,
        dismissible: false,
        elementRef: 'alertErrorDiv',
      });
      this.alertErrorCreado = true;

      this.dialogError.onResult().subscribe(
        (closed) => this.closedError(),
        (dismissed) => this.dismissed(),
        () => this.competed()
      );
    }
  }

  closedError() {
    this.alertErrorCreado = false;
  }

  getCustomerData(token: string): Observable<any> {
    const fechaAct = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss.SSSS");
    let observableResponse: Subject<any> = new Subject();
    const datos: RequestCustomer = {
      documentType: this.formStepOne.value.documentType,
      documentNumber: this.formStepOne.value.documentNumber,
      paymentReference: this.formStepOne.value.paymentReference,
      agreeTermsAndConditions: true,
      recaptcha: token,
      dateTimeRequest: fechaAct != null ? fechaAct : new Date().toDateString()
    };
    this.customerSubscription = this.customerUseCase.getDetailsCustomer(datos).subscribe((data) => {
      if (data instanceof HttpErrorResponse) {
        if (data.error.error == '409' || data.error.error == '404') {
          this.serviceLoading.consulting.emit(false);
          this.errorMessage = constants.message_validation_data_error;
          this.openAlertError();
        } else {
          this.serviceLoading.consulting.emit(false);
          this.errorMessage = constants.message_connection_error;
          this.openAlertError();
        }
        observableResponse.complete();
      } else {
        observableResponse.next(data);
        observableResponse.complete();
        localStorage.setItem('infoServices', JSON.stringify(data));
        let dataForm: DataFormPayment = {
          documentType: this.formStepOne.value.documentType,
          documentNumber: this.formStepOne.value.documentNumber,
          referencePayment: this.formStepOne.value.paymentReference
        }
        localStorage.setItem('dataFormPayment', JSON.stringify(dataForm));
      }
      this.removeAllTagNgVersion();
    });

    return observableResponse.asObservable();
  }

  public executeCaptcha() {
    if (environment.production) {
      this.captcha.execute();
      let subject= new Subject<string>();
      if(this.tokenSubscription)
        this.tokenSubscription.unsubscribe();
      this.tokenSubscription = this.captcha.resolved.subscribe( (token) => {
        if(token != null && token != ''){
          subject.next(token);
          subject.complete();
          this.resetCaptcha();
        }
      });
      return subject.asObservable();
    }
    let subject = new BehaviorSubject({} as string)
    subject.next('token');
    return subject.asObservable();
  }

  public resetCaptcha() {
    if (environment.production){
      this.captcha.reset();
    }
  }

}