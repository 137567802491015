import { StepFourComponent } from './../step-four/step-four.component';
import { Router } from '@angular/router';
import { StepOneComponent } from './../step-one/step-one.component';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BcStepperComponent } from '@bancolombia/design-system-web/bc-stepper';
import { environment } from '../../../../environments/environment';
import { ResponseCustomer } from 'src/app/domain/models/response-customer';
import { StepTwoComponent } from '../step-two/step-two.component';
import { StepThreeComponent } from '../step-three/step-three.component';
import { LoadingServiceService } from 'src/app/infraestructure/services/loading-service/loading-service.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as CryptoJS from 'crypto-js';
import { DataFromPayLinkService } from 'src/app/infraestructure/services/customer-from-paymentlink/data-from-pay-link.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy  {
  // Componente stepper
  @ViewChild('stepperPagos') public stepper: BcStepperComponent;
  // Variable con el valor del paso actual
  public stepPresent: number;
  // Variable con el contenido del boton de continuar
  public botonContinue = "CONSULTAR";
  // Variable con el contenido del boton de cancelar
  public buttonCancel = "CANCELAR";
  // Variable para cambiar el orden de los botones en versión mobile
  public activateMobile = false;
  // Variable con resolución de pantalla mobile widht
  private widthMobile = 576;
  // Variables con los numeros de cad paso del stepper
  private oneNumber = 1;
  private twoNumber = 2;
  private threeNumer = 3;
  private fourNumber = 4;
  // Step one
  @ViewChild(StepOneComponent) stepOne: StepOneComponent;
  // Step two
  @ViewChild(StepTwoComponent) stepTwo: StepTwoComponent;
  // Step three
  @ViewChild(StepThreeComponent) stepThree: StepThreeComponent;
  // Step four
  @ViewChild(StepFourComponent) stepFour: StepFourComponent;


  // Variable para la respuesta de los datos de consulta de consumo
  public responseCustomer: ResponseCustomer;
  // Variable para activar el componente loading
  public requesting: boolean = true;
  // Variable para apuntar el focus y scroll
  @ViewChild('targetScroll') targetScroll: any;
  // Función para la detección de resolución en tiempo real
  public innerWidth: any;
  // Variable para guardar datos del formulario en paso 2
  public formularioTwo: any;
  // Datos de transacción de Wompi para paso 4 o paso 1
  public urlTree: any;
  public idTransaction: any;

  // Subscripcion para escuchar eventos de link de pagos
  subFromPaymentLink: Subscription;

  constructor(
    public serviceLoading: LoadingServiceService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    private fromPaymentLink: DataFromPayLinkService ) {
    this.responseCustomer = {
      loanId: "056455",
      productGroupDescription: "043",
      dateNextPayment: "01/02/2020",
      currentMinimumPayment: 10000,
      currentTotalPayment: 60000,
      idTransactionInformation: 15,
      idPaymentWompi: "18",
      name: " ",
      phone: " ",
      email: " "
    };
  }


  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (screen.width < this.widthMobile){
      this.activateMobile = true;
    }

    // Subscripcion para cambiar al componente de carga o portal de pagos
    this.serviceLoading.consulting.subscribe( (state) => {
      this.requesting = state;
      this.cdRef.detectChanges();
    });

    // Adquisición de datos de Wompi por URL
    this.urlTree = this.router.parseUrl(this.router.url);
    this.idTransaction = this.urlTree.queryParams['id'];

  }


  ngAfterViewInit(): void {
    // Si se envian datos de transacción Wompi
    if( this.idTransaction != undefined && localStorage.getItem('infoServices') != null ){
      this.stepPresent = this.fourNumber;
      this.stepper.writeValue(this.threeNumer);
      this.focusStepper();
      localStorage.setItem('idWompi', this.idTransaction);
      this.responseCustomer = JSON.parse(localStorage.getItem('infoServices')!)
      // Envio de paso actual a Google analitycs
      this.gtmService.pushTag({
        nombreflujo: "Sufi Pagar",
        event: "FLUJO_SUFI_PAGAR",
        paso: "4"
      });
    }
    else{
      this.stepPresent = this.oneNumber;
      this.requesting = false;
      localStorage.clear();
      // Envio de paso actual a Google analitycs
      this.gtmService.pushTag({
        nombreflujo: "Sufi Pagar",
        event: "FLUJO_SUFI_PAGAR",
        paso: "1"
      });
    }

    // Event emitter en caso de que se oprima uno de los botones del stepper
    this.stepper.valueOutput.subscribe( (newPos) => {
      if(this.stepPresent > newPos + this.oneNumber){
        setTimeout( () => { this.stepper.writeValue(this.stepPresent - this.oneNumber) }, this.oneNumber );
      }
    });

    // Subscripcion para recibir data de link de pago
    this.subFromPaymentLink = this.fromPaymentLink.currentMessage.subscribe({
      next: (value) => {
        if(value != ''){
          //console.log("Recibido de link de pagos: ", value);
          this.responseCustomer = value as unknown as ResponseCustomer;
          this.stepper.next();
          this.stepPresent = 2;
          this.updateButtonContent();
          this.requesting = false;
          localStorage.setItem('infoServices', JSON.stringify(this.responseCustomer));
          // Envio de paso actual a Google analitycs
          this.gtmService.pushTag({
            nombreflujo: "Sufi Pagar",
            event: "FLUJO_SUFI_PAGAR",
            paso: "2"
          });
        }
      }
    });

    this.updateButtonContent();
    this.cdRef.detectChanges();
  }


  ngOnDestroy(): void {
    this.serviceLoading.consulting.unsubscribe();
    //this.subFromPaymentLink.unsubscribe();

    this.cdRef.detectChanges();
  }


  // Funciones para focus y scroll de la pantalla
  focusStepper(){
    this.targetScroll.nativeElement.focus();
    window.scroll({
      top: this.getTopOffset(this.targetScroll),
      left: 0,
      behavior: "smooth"
    });
  }

  private getTopOffset(controlEl: ElementRef): number {
    const labelOffset = 10;
    return controlEl.nativeElement.getBoundingClientRect().top + window.scrollY - labelOffset;
  }


  // Función de detección de resolución en tiempo real
  @HostListener ('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < this.widthMobile){
      this.activateMobile = true;
    }
    else{
      this.activateMobile = false;
    }
  }


  // Configuración de stepper
  stepsArray: string[] = ['Ingresa tus datos', 'Confirma el valor', 'Realiza el pago', 'Descarga tu comprobante'];
  indexPosition = 0 // Posición inicial del stepper

  // Funcion para aumentar stepper
  nextStep() {
    switch (this.stepPresent) {
      case this.oneNumber: {
        //this.focusStepper();
        this.getCompleteStepOne();
        break;
      }
      case this.twoNumber: {
        this.getCompleteStepTwo();
        break;
      }
      case this.threeNumer: {
        this.getCompleteStepTwo();
        break;
      }
      case this.fourNumber: {
        this.stepFour.generatePDF();
        break;
      }
    }
  }


  // Función para cancelar la transacción y recargar el stepper
  cancelPayment() {
    document.location.href= environment.homeUrl;
  }


  // Función para actualizar el contenido del boton
  updateButtonContent() {
    if (this.stepPresent == this.oneNumber){
      this.botonContinue = "CONSULTAR";
      this.buttonCancel = "CANCELAR";
    }
    else if (this.stepPresent == this.twoNumber || this.stepPresent == this.threeNumer) {
      this.botonContinue = "REALIZAR EL PAGO"
      this.buttonCancel = "CANCELAR";
    }
    else if (this.stepPresent == this.fourNumber){
      this.botonContinue = "DESCARGAR COMPROBANTE";
      this.buttonCancel = "REALIZAR OTRO PAGO";
    }
  }


  // Función para pasar de paso 1 a 2
  getCompleteStepOne() {
    if (this.stepOne.formStepOne.valid) {
      this.stepOne.executeCaptcha().subscribe((token) =>{
        this.requesting = true;
        setTimeout(() => {
          this.focusStepper();
        }, 100);
        if (token != null && token != '') {
          this.stepOne.getCustomerData(token).subscribe((data) => {
            setTimeout(() => {
              this.focusStepper();
            }, 100);
            this.responseCustomer = data;
            this.stepper.next();
            this.stepPresent = this.twoNumber;
            this.updateButtonContent();
            this.requesting = false;
            localStorage.setItem('infoServices', JSON.stringify(this.responseCustomer));
            // Envio de paso actual a Google analitycs
            this.gtmService.pushTag({
              nombreflujo: "Sufi Pagar",
              event: "FLUJO_SUFI_PAGAR",
              paso: "2"
            });
            // Google tag
            let docTypeGT = this.stepOne.formStepOne.get('documentType')?.value;
            let docNumGT = this.stepOne.formStepOne.get('documentNumber')?.value;
            if (docTypeGT == "CC"){
              docTypeGT = "1"
            }
            else if (docTypeGT == "CE"){
              docTypeGT = "2"
            }
            else if (docTypeGT == "NIT"){
              docTypeGT = "3"
            }
            //console.log("Tipo de documento y numero: ", docTypeGT, docNumGT);

            const salt = CryptoJS.enc.Hex.parse("68c4283db8074b12df1660b31c0220a9");
            const docNumWordArray = CryptoJS.HmacSHA512(docNumGT, salt);
            const docNumHex = CryptoJS.enc.Hex.stringify(docNumWordArray);

            const typeAndDoc = docTypeGT + "-" + docNumGT;
            const typeAndDocWordArray = CryptoJS.HmacSHA512(typeAndDoc, salt);
            const typeAndDocHex = CryptoJS.enc.Hex.stringify(typeAndDocWordArray);
            //console.log("Data que se envia: ", typeAndDocHex, docNumHex);
            this.gtmService.pushTag({
              event: 'SEND_USERID',
              tipo_doc: typeAndDocHex,
              userId: docNumHex
            });
            // ----------------
          });
        }
        else{
          this.requesting = false;
        }
      });
    }
    else {
      this.focusStepper();
      if(this.stepOne.formStepOne.value.documentType == ""){
        this.stepOne.stateDocType = "error";
      }
      if(this.stepOne.formStepOne.value.documentNumber == ""){
        this.stepOne.stateDocNum = "error";
      }
      if(this.stepOne.formStepOne.value.paymentReference == ""){
        this.stepOne.statePayRef = "error";
      }
    }
  }

  getCompleteStepTwo(){
    this.stepTwo.getCompleteStepTwo();
  }

  cancelar() {
    this.cancelPayment();
  }

  goPayment(formTwo:any){
    this.formularioTwo = formTwo;
    if (this.stepPresent === this.twoNumber){
      this.stepper.next();
    }
    else{
      this.stepThree.openWompi();
    }
    this.stepPresent = this.threeNumer;
    // Envio de paso actual a Google analitycs
    this.gtmService.pushTag({
      nombreflujo: "Sufi Pagar",
      event: "FLUJO_SUFI_PAGAR",
      paso: "3"
    });
  }
}